import React, { useEffect, useRef, useState } from 'react';
import './ScormCoursePopup.scss';
import '../../../theme/_buttons.scss';
import axios from "axios";
import xmlJs from 'xml-js';
import SCORM_API from '../services/scormapi';


const ScormCoursePopup = ({ openScormCourse, setOpenScormCourse, scormURL }) => {
    const [rootFileURL, setRootFileURL] = useState('')
    window.API = SCORM_API;

    const iframeRef = useRef();
    useEffect(() => {
      const iframe = iframeRef.current;
      if (iframe) {
        iframe.addEventListener("load", () => {
          console.log("Iframe loaded");
  
          // Access iframe content if needed
          try {
            const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
            console.log("Iframe document loaded", iframeDocument);
          } catch (error) {
            console.error("Error accessing iframe content:", error);
          }
        });
      }
      getImsManifestValue();
    }, []);
    const getImsManifestValue = async() => {
        axios.get(scormURL)
        .then(function (response) {
            const jsonData = JSON.parse(xmlJs.xml2json(response.data, { compact: true, spaces: 4 }));
            const filName = scormURL.substring(scormURL.lastIndexOf("/") + 1);
            const rootURL = "https://test.carelearner.com/scorm/courses/untitled1/"
            setRootFileURL(rootURL.concat(jsonData.manifest.resources.resource._attributes.href))
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })

    }

    const handleScormPopupClose = () => {
        setOpenScormCourse(false)
    }
    
  return (
    <div className='app-scorm_popup' style={{ display: openScormCourse ? 'block': 'none'}}>
        <div className='app-scorm_popup-close'>
            <button className='finish-button' onClick={handleScormPopupClose} >Close</button>
        </div>
        <div className='app-scorm_popup-container'>
            <iframe
                ref={iframeRef}
                src={rootFileURL}
                width="100%"
                height="100%"
            ></iframe>
        </div>
    </div>
  )
}

export default ScormCoursePopup
