
const SCORM_API = {
    data: {},
    
    LMSInitialize: function() {
        console.log("LMSInitialize called");
        return "true";
    },
    LMSFinish: function() {
        console.log("LMSFinish called");
        return "true";
    },
    LMSGetValue: function(key) {
        console.log(`LMSGetValue called with key: ${key}`);
        return "";
    },
    LMSSetValue: function(key, value) {
        console.log(`LMSSetValue called with key: ${key}, value: ${value}`);
        this.data[key]=value;
        this.data['cmi.core.lesson_status']='incomplete';
        this.data['cmi.core.exit']='suspend';
        return "true";
    },
    LMSCommit: function() {
        console.log("LMSCommit called");
        return "true";
    },
    LMSGetLastError: function() {
        return "0"; // No error
    },
    LMSGetErrorString: function(errorCode) {
        return "No error"; // Just a default message
    },
    LMSGetDiagnostic: function(errorCode) {
        return "No diagnostic information available";
    }
};
  
export default SCORM_API;
  